import Framework7 from 'framework7/framework7.esm.bundle.js';
import _ from 'lodash';
const getBaseURL = () => {
	const env = process.env.NODE_ENV;
	const isDev = env === 'development';
	return isDev ? 'http://localhost:3000/api' : 'https://pickaplace.app/api';
};
console.log(`MY BASE URL IS ${getBaseURL()}`);

class APIService {
	test() {
		console.log('Test');
	}

	get(url, noBase) {
		try {
			return new Promise((resolve, reject) => {
				Framework7.request({
					method: 'GET',
					url: noBase ? url : getBaseURL() + url,
					crossDomain: true,
					success: function (res) {
						try {
							resolve(JSON.parse(res));
						} catch {
							resolve(res);
						}
					},
					error: (xhr) => {
						this.handleError(xhr);
						reject(xhr);
					}
				});
			});
		} catch {
			return Promise.reject('Unable to access API');
		}
	}

	post(url, data, noBase) {
		return new Promise((resolve, reject) => {
			Framework7.request({
				method: 'POST',
				contentType: 'application/json',
				url: noBase ? url : getBaseURL() + url,
				data: data,
				crossDomain: true,
				success: function (res) {
					try {
						resolve(JSON.parse(res));
					} catch {
						resolve(res);
					}
				},
				error: (xhr) => {
					this.handleError(xhr);
					reject(xhr);
				}
			});
		});
	}

	zipLookup(zip) {
		return this.get(
			`https://public.opendatasoft.com/api/records/1.0/search/?dataset=us-zip-code-latitude-and-longitude&q=${zip}&rows=1&facet=state&facet=timezone&facet=dst`,
			true
		);
	}
}
export default new APIService();
