import React, { Component } from 'react';
import { Page, Button } from 'framework7-react';
import { observer, autoBind, observable } from '../utils/State';
import TinderCard from 'react-tinder-card';
import { animateOut } from 'react-tinder-card';
import _, { matches } from 'lodash';
import copy from 'copy-to-clipboard';
import StorageService from '../services/StorageService';
import zomato from '../assets/zomato.json';
import logo from '../assets/logo.svg';
import LocationService from '../services/LocationService';
import appStore from '../stores/AppStore';
import '../css/session-page.scss';
import app from '../components/app';
import FoodImages from './FoodImages';
const storageService = new StorageService();
const locationService = new LocationService();
const shuffleArray = function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
};
shuffleArray(zomato.cuisines);

@observer
export default class SessionPage extends Component {
	constructor() {
		super();
		this.data = observable({
			sessions: [],
			invitationPrompt: false,
			processedCards: [],
			participants: [],
			liked: [],
			disliked: [],
			zomato: zomato,
			firstLoad: true,
			showThumb: null,
			match: null
		});
		this.activeCardRef = React.createRef();
		autoBind(this);
	}

	async componentDidMount() {
		this.$f7.dialog.close();
		const unsubscribe = await firebase
			.firestore()
			.collection('sessions')
			.where('sessionId', '==', appStore.activeSessionId)
			.onSnapshot((snapshot) => {
				let sessions = [];
				let participants = [];
				let mySession = null;
				console.log(`Call from did mount snapshot`);
				snapshot.forEach((doc) => {
					let record = doc.data();
					if (record.user === appStore.currentUserId) {
						mySession = record;
					}
					participants.push(record.userName);
					sessions.push(record);
				});

				if (mySession && this.data.firstLoad) {
					this.data.processedCards = [...mySession.liked, ...mySession.disliked];
					this.data.firstLoad = false;
				}
				this.data.showThumb = null;
				this.data.sessions = sessions;
				this.data.participants = participants;
				if (!this.data.match) {
					this.completionCheck();
				}
				if (sessions.length > 1 && !this.data.invitationPrompt && this.props.invited && !this.getIsParent()) {
					let parent = this.getParent();
					this.$f7.dialog.alert(
						`${parent.userName} invited you to find something to eat! Swipe left or right on foods to find a match.`,
						() => {
							this.data.invitationPrompt = true;
						}
					);
				}
			});
		this.beforeClose = () => {
			console.log(`Unsubscribing from session ${appStore.activeSessionId}`);
			unsubscribe();
		};
	}

	async onBack() {
		if (this.data.match) {
			this.$f7.dialog.preloader();
			this.beforeClose();
			await firebase
				.firestore()
				.collection('sessions')
				.where('sessionId', '==', appStore.activeSessionId)
				.get()
				.then((querySnapshot) => {
					let batch = firebase.firestore().batch();
					let parentDoc = {};
					console.log(`Call from onBack snapshot`);
					querySnapshot.docs.forEach((doc) => {
						const docRef = firebase.firestore().collection('sessions').doc(doc.id);
						batch.update(docRef, {
							completed: true
						});
						if (doc.data().parent) {
							parentDoc = doc.data();
						}
					});
					batch.commit();
					this.$f7.dialog.close();
					this.$f7router.back();
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
					this.$f7.dialog.close();
					this.$f7router.back();
				});
		} else {
			this.beforeClose();
			this.$f7router.back();
		}
	}

	completionCheck() {
		let parent = _.find(this.data.sessions, { parent: true });
		if (parent && parent.completed) {
			this.$f7.dialog.alert('Session was marked completed by creator. Hope you found something good to eat!');
			this.onBack();
		}
		if (this.data.sessions.length > 1) {
			this.matchCheck();
		}
	}

	matchCheck() {
		let likedArrays = [];
		_.forEach(this.data.sessions, (s) => {
			likedArrays.push(_.cloneDeep(s.liked));
		});

		var result = likedArrays.shift().reduce(function (res, v) {
			if (
				res.indexOf(v) === -1 &&
				likedArrays.every(function (a) {
					return a.indexOf(v) !== -1;
				})
			)
				res.push(v);
			return res;
		}, []);

		if (result[0]) {
			this.data.match = result[0];
		}
	}

	getParent() {
		return _.find(this.data.sessions, { parent: true });
	}

	getIsParent() {
		let isParent = false;
		let parent = this.getParent();
		if (parent && parent.user === appStore.currentUserId) {
			isParent = true;
		}
		return isParent;
	}

	async submitChoice(name, liked) {
		if (!liked) {
			this.data.disliked.push(name);
		}
		if (liked && this.data.liked.length < 3) {
			this.data.liked.push(name);
		}
		let toProcess = _.reverse(
			this.data.zomato.cuisines
				.map((rec) => {
					if (this.data.processedCards.indexOf(rec.cuisine.cuisine_name) < 0) {
						return rec;
					}
					return null;
				})
				.filter((c) => {
					return c !== null;
				})
		);
		if ((liked && this.data.liked.length >= 3) || toProcess.length === 0) {
			firebase
				.firestore()
				.collection('sessions')
				.where('sessionId', '==', appStore.activeSessionId)
				.where('user', '==', appStore.currentUserId)
				.get()
				.then((querySnapshot) => {
					querySnapshot.docs.forEach(async (doc) => {
						console.log(`Call from submit snapshot`);
						let batch = firebase.firestore().batch();
						const docRef = await firebase.firestore().collection('sessions').doc(doc.id);
						let existing = doc.data();
						let allLiked = _.clone(existing.liked || []);
						let allDisliked = _.clone(existing.disliked || []);
						_.forEach(this.data.liked, (name) => {
							if (existing.liked.indexOf(name) < 0 && existing.disliked.indexOf(name) < 0) {
								allLiked.push(name);
							}
						});
						_.forEach(this.data.disliked, (name) => {
							if (existing.liked.indexOf(name) < 0 && existing.disliked.indexOf(name) < 0) {
								allDisliked.push(name);
							}
						});
						batch.update(docRef, { liked: allLiked, disliked: allDisliked });
						batch.commit();
						this.data.liked = [];
						this.data.disliked = [];
					});
				})
				.catch(function (error) {
					console.log('Error getting documents: ', error);
				});
		}
	}

	dislikeFood(cuisine) {
		console.log(`Disliked ${cuisine.cuisine_name}`);
		this.data.processedCards.push(cuisine.cuisine_name);
		this.submitChoice(cuisine.cuisine_name, false);
	}

	likeFood(cuisine) {
		console.log(`Liked ${cuisine.cuisine_name}`);
		this.data.processedCards.push(cuisine.cuisine_name);
		this.submitChoice(cuisine.cuisine_name, true);
	}

	getCards() {
		let toProcess = _.reverse(
			this.data.zomato.cuisines
				.map((rec) => {
					if (this.data.processedCards.indexOf(rec.cuisine.cuisine_name) < 0) {
						return rec;
					}
					return null;
				})
				.filter((c) => {
					return c !== null;
				})
		);
		let items = [];
		_.forEach(toProcess, (rec) => {
			if (items.length === 2) {
				return;
			}
			items.push(
				<TinderCard
					className={`food-card vbox vcenter hcenter image-${rec.cuisine.cuisine_id} ${false ? 'shadow' : ''}`}
					key={rec.cuisine.cuisine_name.split(' ').join('')}
					ref={items.length === 0 ? this.activeCardRef : null}
					onSwipe={(direction) => {
						if (direction == 'left' || direction == 'right') {
							switch (direction) {
								case 'left': {
									this.dislikeFood(rec.cuisine);
									this.data.showThumb = `fa-thumbs-down`;
									break;
								}
								case 'right': {
									this.likeFood(rec.cuisine);
									this.data.showThumb = `fa-thumbs-up`;
									break;
								}
							}
						}
					}}
					preventSwipe={['up', 'down']}
				>
					<div className="img-ctn">
						<img src={FoodImages[`image${rec.cuisine.cuisine_id}`]} />
						<div className="name vbox vcenter hcenter">{rec.cuisine.cuisine_name}</div>
					</div>
				</TinderCard>
			);
		});
		return items;
	}

	//! TODO show all matches at end like votes if there is no consensus

	render() {
		let { sessions, showThumb, match, participants } = this.data;
		const cards = sessions.length > 1 ? this.getCards() : [];
		return (
			<Page className="session-page">
				<div className="header hbox vcenter hcenter">
					<Button className="hbox vcenter hcenter" onClick={this.onBack}>
						<i className="fas fa-arrow-left"></i> Back
					</Button>
					<div className="grow-1 vbox vcenter hcenter">
						<img className="logo" src={logo} />
					</div>
					<Button
						onClick={() => {
							if (navigator.share) {
								const shareData = {
									title: 'Hungry?',
									text: 'Start swiping to help align our cravings!',
									url: `${window.location.origin}/?sessionId=${appStore.activeSessionId}`
								};
								navigator.share(shareData);
							} else {
								let url = `${window.location.origin}/?sessionId=${appStore.activeSessionId}`;
								copy(url.toString());
								this.$f7.dialog.alert(
									'Send this link to anyone who you wish to particapate in matching (a match only occurs when ALL participants match).',
									'Link copied to clipboard.'
								);
							}
						}}
					>
						<i className="fas fa-share-alt"></i>
						Invite
					</Button>
				</div>
				<div className="main-content">
					{sessions.length == 1 && (
						<div className="invite-prompt">
							<div>Invite at least one participant to start swiping!</div>
							<Button
								fill
								large
								className="invite-btn"
								onClick={() => {
									if (navigator.share) {
										const shareData = {
											title: 'Hungry?',
											text: 'Start swiping to help align our cravings!',
											url: `${window.location.origin}/?sessionId=${appStore.activeSessionId}`
										};
										navigator.share(shareData);
									} else {
										let url = `${window.location.origin}/?sessionId=${appStore.activeSessionId}`;
										copy(url.toString());
										this.$f7.dialog.alert(
											'Send this link to anyone who you wish to particapate in matching (a match only occurs when ALL participants match).',
											'Link copied to clipboard.'
										);
									}
								}}
							>
								<i className="fas fa-share-alt"></i>
								Invite
							</Button>
						</div>
					)}
					{showThumb && (
						<div
							className="swipe-success vbox vcenter hcenter animated fadeOutUp"
							style={
								showThumb === 'fa-thumbs-up'
									? { right: `${Math.floor(Math.random() * 132) + 32}px` }
									: { left: `${Math.floor(Math.random() * 132) + 32}px` }
							}
							key={Math.random()}
						>
							<i className={`far ${showThumb}`}></i>
						</div>
					)}
					{sessions.length > 1 && (
						<div className="cards-ctn vbox vcenter hcenter">
							<div className={`match-card vbox vcenter hcenter ${!match ? 'absolute' : ''}`}>
								{!match && <div className="pending animated pulse infinite">Waiting on everyone to finish...</div>}
								{match && (
									<div className="match-success vbox hcenter animated zoomIn">
										<div className="name">{match}</div>
										<i className="fas fa-heart animated pulse infinite"></i>
										<h2>You found a match!</h2>
										<div className="wip">Restaurant search coming soon!</div>
										<Button
											large
											onClick={() => {
												window.open(
													`https://www.google.com/maps/search/${match.split(' ').join('+')}+Food/@${appStore.currentLocation.lat},${
														appStore.currentLocation.lon
													},11z/data=!3m1!4b1`,
													'_blank'
												);
											}}
										>
											Google Maps Search
										</Button>
									</div>
								)}
							</div>
							{!match && (
								<div id="card-list" className="cards-list vbox vcenter hcenter">
									{cards}
								</div>
							)}
						</div>
					)}
					{!match && sessions.length > 1 && cards.length > 0 && (
						<div className="card-controls hbox vcenter hcenter" key={`active-card-controls`}>
							<div
								className="card-btn dislike"
								onClick={async () => {
									try {
										await this.activeCardRef.current.swipe('left');
									} catch (err) {}
								}}
							>
								<i className="far fa-thumbs-down" />
							</div>
							<div
								className="card-btn"
								onClick={async () => {
									try {
										await this.activeCardRef.current.swipe('right');
									} catch (err) {}
								}}
							>
								<i className="far fa-thumbs-up" />
							</div>
						</div>
					)}
					{participants.length > 1 && (
						<div className="status vbox vcenter hcenter">
							<h4>Currently Swiping</h4>
							<div className="participants">
								{participants.map((p) => {
									return (
										<div className="participant" key={Math.random()}>
											{p}
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</Page>
		);
	}
}
