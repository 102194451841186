import React, { Component } from 'react';
import { Page, Button, Preloader, Actions, ActionsGroup, ActionsButton, ActionsLabel } from 'framework7-react';
import { observer, autoBind, observable } from '../utils/State';
import { formatRelative } from 'date-fns';
import _ from 'lodash';
import StorageService from '../services/StorageService';
import logo from '../assets/logo.svg';
import { v4 as uuidv4 } from 'uuid';
import LocationService from '../services/LocationService';
import appStore from '../stores/AppStore';
import Waves from '../components/Waves';
const storageService = new StorageService();
const locationService = new LocationService();
import '../css/home.scss';
import thankYou from '../assets/thankYou.svg';
import GeneralUtils from '../services/GeneralUtils';

@observer
export default class HomePage extends Component {
	constructor() {
		super();
		this.data = observable({ activeTab: 'home', sessionActionOpen: false, selectedSession: null });
		autoBind(this);
	}

	async componentDidMount() {
		let locationData = await storageService.getValue('app', 'location');
		if (locationData && locationData.city !== 'Unknown') {
			appStore.currentLocation = locationData;
		}
	}

	async completeSession() {
		await firebase
			.firestore()
			.collection('sessions')
			.where('sessionId', '==', this.data.selectedSession)
			.get()
			.then((querySnapshot) => {
				let batch = firebase.firestore().batch();
				let parentDoc = {};
				querySnapshot.docs.forEach((doc) => {
					const docRef = firebase.firestore().collection('sessions').doc(doc.id);
					batch.update(docRef, {
						completed: true
					});
					if (doc.data().parent) {
						parentDoc = doc.data();
					}
				});
				if (parentDoc.user == appStore.currentUserId) {
					batch.commit();
				} else {
					this.$f7.dialog.alert('Only the creator can close the session');
				}
			})
			.catch(function (error) {
				console.log('Error getting documents: ', error);
			});

		this.data.selectedSession = null;
	}

	async startNewSessiton() {
		if (_.get(appStore, 'currentLocation.city', 'Unknown') === 'Unknown') {
			GeneralUtils.getLocation(this.$f7)
				.then(async () => {
					await GeneralUtils.nameCheck(this.$f7);
					this.$f7.dialog.preloader();
					let sessionId = uuidv4();
					let user = firebase.auth().currentUser;
					await firebase.firestore().collection('sessions').doc().set({
						sessionId: sessionId,
						user: user.uid,
						userName: user.displayName,
						createdDate: new Date().getTime(),
						parent: true,
						completed: false,
						location: appStore.currentLocation,
						liked: [],
						disliked: []
					});
					appStore.activeSessionId = sessionId;
					this.openSessionPage();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			await GeneralUtils.nameCheck(this.$f7);
			this.$f7.dialog.preloader();
			let sessionId = uuidv4();
			let user = firebase.auth().currentUser;
			await firebase.firestore().collection('sessions').doc().set({
				sessionId: sessionId,
				user: user.uid,
				userName: user.displayName,
				createdDate: new Date().getTime(),
				parent: true,
				completed: false,
				location: appStore.currentLocation,
				liked: [],
				disliked: []
			});
			appStore.activeSessionId = sessionId;
			this.openSessionPage();
		}
	}

	openSessionPage() {
		this.$f7.dialog.close();
		this.$f7router.navigate(`/session/${appStore.activeSessionId}`, {
			props: {
				parentSession: true
			}
		});
	}

	buildActiveContent() {
		switch (this.data.activeTab) {
			case 'home': {
				if (!appStore.sessionsLoaded) {
					return (
						<div className="loading-sessions vbox vcenter hcenter">
							<Preloader></Preloader>
						</div>
					);
				}
				return (
					<div className="card-ctn">
						<div className="hero-card start vbox hcenter vcenter animated zoomIn">
							<div className="text">Swipe right on food you like</div>
							<div className="subtext">Swipe left food you don't</div>
							<Button large className="start-btn" onClick={this.startNewSessiton}>
								Start
							</Button>
						</div>
					</div>
				);
			}
			case 'history': {
				return (
					<div className="history-ctn vbox hcenter animated fadeInLeft y-scroll">
						<h3>{appStore.activeSessions.length === 0 ? 'Sessions' : 'Open Sessions'}</h3>
						{appStore.activeSessions.map((s) => {
							return (
								<div
									className="session hbox vcenter"
									key={Math.random()}
									onClick={() => {
										this.data.selectedSession = s.sessionId;
										this.data.sessionActionOpen = true;
									}}
								>
									<div className="date">{formatRelative(new Date(s.createdDate), new Date())}</div>
									<div className="disliked hbox vcenter">
										{s.disliked.length} <i className="far fa-thumbs-down"></i>
									</div>
									<div className="liked hbox vcenter">
										{s.liked.length} <i className="far fa-thumbs-up"></i>
									</div>
								</div>
							);
						})}
						{appStore.activeSessions.length === 0 && <div className="empty-msg">There are currently no open sessions.</div>}
						<Button fill large className="new-btn animated pulse infinite" onClick={this.startNewSessiton}>
							New Session
						</Button>
					</div>
				);
			}
			case 'account': {
				return (
					<div className="account-ctn vbox hcenter animated fadeInRight y-scroll">
						<div className="thanks-ctn">
							<img
								className="thanks"
								src={thankYou}
								onClick={() => {
									window.open('https://www.buymeacoffee.com/socialutilities', '_blank');
								}}
							></img>
							<p>
								Saving account details and history is coming soon! <br></br>
								<br></br>Thank you for your feedback and support, if you would like to help keep grumbl running tap the button below to
								buy me a coffee and learn more about my mission.
							</p>
							<Button
								fill
								large
								onClick={() => {
									window.open('https://www.buymeacoffee.com/socialutilities', '_blank');
								}}
							>
								Thank You!
							</Button>
						</div>
					</div>
				);
			}
		}
	}

	render() {
		let { activeTab } = this.data;
		return (
			<Page name="home" className="home-page">
				<div className="main-content">
					<img className="logo" src={logo} />
					<h1>Feeling Hungry?</h1>
					<Button
						large
						className="location-btn hbox vcenter hcenter"
						onClick={() => {
							GeneralUtils.getLocation(this.$f7);
						}}
					>
						<i className="fas fa-map-marker-alt"></i>{' '}
						{appStore.currentLocation.city === 'Unknown' ? 'Set Location' : appStore.currentLocation.city}
					</Button>
					{this.buildActiveContent()}
					<div className="menu-bar hbox vcenter hcenter">
						<i
							className={`fas fa-history ${activeTab === 'history' ? 'active' : ''}`}
							onClick={() => {
								this.data.activeTab = 'history';
							}}
						></i>
						{appStore.activeSessions.length > 0 ? <div className="dot"></div> : ''}
						<div className="divider"></div>
						<i
							className={`fas fa-home ${activeTab === 'home' ? 'active' : ''}`}
							onClick={() => {
								this.data.activeTab = 'home';
							}}
						></i>
						<div className="divider"></div>
						<i
							className={`fas fa-user ${activeTab === 'account' ? 'active' : ''}`}
							onClick={() => {
								this.data.activeTab = 'account';
							}}
						></i>
					</div>
				</div>
				<Waves />
				<Actions
					id="session-actions"
					opened={this.data.sessionActionOpen}
					onActionsClosed={() => {
						this.data.sessionActionOpen = false;
					}}
				>
					<ActionsGroup>
						<ActionsLabel bold>View or Close this Session</ActionsLabel>
						<ActionsButton
							onClick={() => {
								appStore.activeSessionId = _.clone(this.data.selectedSession);
								this.data.selectedSession = null;
								this.openSessionPage();
							}}
						>
							View Session
						</ActionsButton>
						<ActionsButton color="red" onClick={this.completeSession}>
							Close Session
						</ActionsButton>
					</ActionsGroup>
					<ActionsGroup>
						<ActionsButton color="black">Cancel</ActionsButton>
					</ActionsGroup>
				</Actions>
			</Page>
		);
	}
}
