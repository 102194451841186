import _ from 'lodash';
import app from '../components/app';
import appStore from '../stores/AppStore';
import APIService from './APIService';
import StorageService from './StorageService';
const storageService = new StorageService();
export default class LocationService {
	async setPosition(lat, lon) {
		const getPosition = firebase.functions().httpsCallable('getPosition');
		return getPosition({ lat, lon }).then(async (res) => {
			if (res.data && res.data.city) {
				appStore.currentLocation = res.data;
				await storageService.setValue('app', 'location', appStore.currentLocation);
				console.log(res.data);
			}
		});
	}
	async getZipLocation(zip) {
		const data = await APIService.zipLookup(zip);
		if (data && data.records.length > 0) {
			let location = _.get(data, 'records[0].fields', {});
			appStore.currentLocation = {
				lat: location.latitude,
				lon: location.longitude,
				city: location.city || 'Unknown'
			};
			return true;
		}
		return false;
	}
}
