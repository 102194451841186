import image1 from '../assets/American.jpg';
import image151 from '../assets/Argentine.jpg';
import image175 from '../assets/Armenian.jpg';
import image3 from '../assets/Asian.jpg';
import image193 from '../assets/BBQ.jpg';
import image955 from '../assets/Bagels.jpg';
import image5 from '../assets/Bakery.jpg';
import image227 from '../assets/Bar-Food.jpg';
import image159 from '../assets/Brazilian.jpg';
import image182 from '../assets/Breakfast.jpg';
import image133 from '../assets/British.jpg';
import image247 from '../assets/Bubble-Tea.jpg';
import image168 from '../assets/Burger.jpg';
import image30 from '../assets/Cafe.jpg';
import image491 from '../assets/Cajun.jpg';
import image158 from '../assets/Caribbean.jpg';
import image25 from '../assets/Chinese.jpg';
import image161 from '../assets/Coffee-and-Tea.jpg';
import image287 from '../assets/Colombian.jpg';
import image881 from '../assets/Crepes.jpg';
import image153 from '../assets/Cuban.jpg';
import image192 from '../assets/Deli.jpg';
import image100 from '../assets/Desserts.jpg';
import image411 from '../assets/Dim-Sum.jpg';
import image541 from '../assets/Diner.jpg';
import image958 from '../assets/Dominican.jpg';
import image959 from '../assets/Donuts.jpg';
import image268 from '../assets/Drinks-Only.jpg';
import image651 from '../assets/Eastern-European.jpg';
import image149 from '../assets/Ethiopian.jpg';
import image38 from '../assets/European.jpg';
import image40 from '../assets/Fast-Food.jpg';
import image112 from '../assets/Filipino.jpg';
import image960 from '../assets/Floribbean.jpg';
import image318 from '../assets/Fondue.jpg';
import image45 from '../assets/French.jpg';
import image501 from '../assets/Frozen-Yogurt.jpg';
import image274 from '../assets/Fusion.jpg';
import image134 from '../assets/German.jpg';
import image156 from '../assets/Greek.jpg';
import image181 from '../assets/Grill.jpg';
import image521 from '../assets/Hawaiian.jpg';
import image143 from '../assets/Healthy-Food.jpg';
import image233 from '../assets/Ice-Cream.jpg';
import image148 from '../assets/Indian.jpg';
import image135 from '../assets/Irish.jpg';
import image55 from '../assets/Italian.jpg';
import image207 from '../assets/Jamaican.jpg';
import image60 from '../assets/Japanese.jpg';
import image164 from '../assets/Juices.jpg';
import image67 from '../assets/Korean.jpg';
import image136 from '../assets/Latin-American.jpg';
import image66 from '../assets/Lebanese.jpg';
import image70 from '../assets/Mediterranean.jpg';
import image73 from '../assets/Mexican.jpg';
import image74 from '../assets/Mongolian.jpg';
import image147 from '../assets/Moroccan.jpg';
import image996 from '../assets/New-American.jpg';
import image162 from '../assets/Peruvian.jpg';
import image82 from '../assets/Pizza.jpg';
import image87 from '../assets/Portuguese.jpg';
import image983 from '../assets/Pub-Food.jpg';
import image361 from '../assets/Puerto-Rican.jpg';
import image320 from '../assets/Ramen.jpg';
import image998 from '../assets/Salad.jpg';
import image304 from '../assets/Sandwich.jpg';
import image83 from '../assets/Seafood.jpg';
import image461 from '../assets/Soul-Food.jpg';
import image972 from '../assets/South-American.jpg';
import image471 from '../assets/Southern.jpg';
import image966 from '../assets/Southwestern.jpg';
import image89 from '../assets/Spanish.jpg';
import image141 from '../assets/Steak.jpg';
import image177 from '../assets/Sushi.jpg';
import image997 from '../assets/Taco.jpg';
import image190 from '../assets/Taiwanese.jpg';
import image179 from '../assets/Tapas.jpg';
import image163 from '../assets/Tea.jpg';
import image964 from '../assets/Teriyaki.jpg';
import image150 from '../assets/Tex-Mex.jpg';
import image95 from '../assets/Thai.jpg';
import image142 from '../assets/Turkish.jpg';
import image308 from '../assets/Vegetarian.jpg';
import image641 from '../assets/Venezuelan.jpg';
import image99 from '../assets/Vietnamese.jpg';
import imagenicole1 from '../assets/Wings.jpg';
import imagenicole2 from '../assets/Kebab.jpg';
import imagenicole3 from '../assets/Korean-BBQ.jpg';
import imagenicole4 from '../assets/Comfort-Food.jpg';
import imagenicole5 from '../assets/Smoothies.jpg';

const FoodImages = {
	image1: image1,
	image151: image151,
	image175: image175,
	image3: image3,
	image193: image193,
	image955: image955,
	image5: image5,
	image227: image227,
	image159: image159,
	image182: image182,
	image133: image133,
	image247: image247,
	image168: image168,
	image30: image30,
	image491: image491,
	image158: image158,
	image25: image25,
	image161: image161,
	image287: image287,
	image881: image881,
	image153: image153,
	image192: image192,
	image100: image100,
	image411: image411,
	image541: image541,
	image958: image958,
	image959: image959,
	image268: image268,
	image651: image651,
	image149: image149,
	image38: image38,
	image40: image40,
	image112: image112,
	image960: image960,
	image318: image318,
	image45: image45,
	image501: image501,
	image274: image274,
	image134: image134,
	image156: image156,
	image181: image181,
	image521: image521,
	image143: image143,
	image233: image233,
	image148: image148,
	image135: image135,
	image55: image55,
	image207: image207,
	image60: image60,
	image164: image164,
	image67: image67,
	image136: image136,
	image66: image66,
	image70: image70,
	image73: image73,
	image74: image74,
	image147: image147,
	image996: image996,
	image162: image162,
	image82: image82,
	image87: image87,
	image983: image983,
	image361: image361,
	image320: image320,
	image998: image998,
	image304: image304,
	image83: image83,
	image461: image461,
	image972: image972,
	image471: image471,
	image966: image966,
	image89: image89,
	image141: image141,
	image177: image177,
	image997: image997,
	image190: image190,
	image179: image179,
	image163: image163,
	image964: image964,
	image150: image150,
	image95: image95,
	image142: image142,
	image308: image308,
	image641: image641,
	image99: image99,
	imagenicole1: imagenicole1,
	imagenicole2: imagenicole2,
	imagenicole3: imagenicole3,
	imagenicole4: imagenicole4,
	imagenicole5: imagenicole5
};
export default FoodImages;
