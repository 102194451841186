import React from 'react';

import { App, View } from 'framework7-react';
import _ from 'lodash';
import routes from '../js/routes';
import StorageService from '../services/StorageService';
import appStore from '../stores/AppStore';
import GeneralUtils from '../services/GeneralUtils';
const storageService = new StorageService();

export default class extends React.Component {
	constructor() {
		super();
		this.state = {
			// Framework7 Parameters
			f7params: {
				// App name
				name: 'grumbl',
				// Automatic theme detection
				theme: 'ios',
				// App routes
				routes: routes,
				// Register service worker
				serviceWorker: {
					path: '/service-worker.js'
				}
			}
		};
	}

	getUrlParams() {
		let url = window.location.search;
		var params = {};
		var parser = document.createElement('a');
		parser.href = url;
		var query = parser.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params;
	}

	async nameCheck() {
		let user = firebase.auth().currentUser;
		if (!user.displayName) {
			return new Promise((resolve) => {
				const getNameDialog = (name) => {
					if (!_.isEmpty(name)) {
						user.updateProfile({ displayName: name }).then(() => {
							resolve();
						});
					} else {
						this.$f7.dialog.close();
						this.$f7.dialog.prompt(`Your friends will see this.`, `What's your name?`, getNameDialog, getNameDialog);
					}
				};
				this.$f7.dialog.prompt(`Your friends will see this.`, `What's your name?`, getNameDialog, getNameDialog);
			});
		}
	}

	async sessionInvite() {
		return new Promise(async (resolve) => {
			const initSession = async () => {
				await GeneralUtils.nameCheck(this.$f7);
				this.$f7.dialog.preloader();
				let user = firebase.auth().currentUser;
				const existing = await firebase.firestore().collection('sessions').where('sessionId', '==', appStore.activeSessionId).get();
				let docs = [];
				let currentSession = [];

				_.forEach(existing.docs, (doc) => {
					docs.push(doc.data());
					if (doc.data().user === user.uid) {
						currentSession.push(doc.data());
					}
				});

				if (_.isEmpty(docs)) {
					console.log('No session with this ID');
					appStore.activeSessionId = null;
				}

				if (!_.isEmpty(docs)) {
					let parent = _.find(docs, { parent: true });
					if (parent && parent.completed) {
						console.log('Session with this ID already completed');
						appStore.activeSessionId = null;
						this.$f7.dialog.close();
						return false;
					}
				}

				if (!_.isEmpty(docs) && _.isEmpty(currentSession)) {
					await firebase.firestore().collection('sessions').doc().set({
						sessionId: appStore.activeSessionId,
						user: user.uid,
						userName: user.displayName,
						createdDate: new Date().getTime(),
						parent: false,
						completed: false,
						location: appStore.currentLocation,
						liked: [],
						disliked: []
					});
					return true;
				}

				if (!_.isEmpty(docs) && currentSession.length === 1) {
					return true;
				}

				this.$f7.dialog.close();
				return false;
			};
			if (_.get(appStore, 'currentLocation.city', 'Unknown') === 'Unknown') {
				await GeneralUtils.getLocation(this.$f7)
					.then(async () => {
						const done = await initSession();
						resolve(done);
					})
					.catch((err) => {
						resolve(false);
						console.log(err);
					});
			} else {
				const done = await initSession();
				resolve(done);
			}
		});
	}

	componentDidMount() {
		storageService.createStore('app');
		this.$f7ready((f7) => {
			// Call F7 APIs here
			try {
				appStore.urlParams = this.getUrlParams();
				if (appStore.urlParams.sessionId) {
					appStore.activeSessionId = _.clone(appStore.urlParams.sessionId);
				}

				let firebaseConfig = {
					apiKey: 'AIzaSyBJxfkBLRyA-cu1gzGJG2Q8qRnVabbsEaU',
					authDomain: 'pickaplace-app.firebaseapp.com',
					databaseURL: 'https://pickaplace-app.firebaseio.com',
					projectId: 'pickaplace-app',
					storageBucket: 'pickaplace-app.appspot.com',
					messagingSenderId: '91375486013',
					appId: '1:91375486013:web:b0d9ae1c6128a5e3f75654'
				};

				//* FOR DEV EMULATION
				if (location.hostname === 'localhost') {
					firebaseConfig = {
						apiKey: 'AIzaSyBJxfkBLRyA-cu1gzGJG2Q8qRnVabbsEaU',
						projectId: 'pickaplace-app',
						appId: '1:91375486013:web:b0d9ae1c6128a5e3f75654',
						databaseURL: 'http://localhost:9000?ns=pickaplace-app'
					};

					//* INIT FIREBASE
					firebase.initializeApp(firebaseConfig);

					//* SETUP FUNCTION
					firebase.functions().useFunctionsEmulator('http://localhost:5123');

					//* CONNECT TO FIRESTORE
					firebase.firestore().settings({
						host: 'localhost:5124',
						ssl: false
					});
				} else {
					firebase.initializeApp(firebaseConfig);
				}
				//* AUTH LISTENER
				firebase.auth().onAuthStateChanged(async (user) => {
					if (user) {
						console.log('User Signed In');
						appStore.currentUserId = user.uid;
						const activeSessUnsub = await firebase
							.firestore()
							.collection('sessions')
							.where('user', '==', user.uid)
							.where('completed', '==', false)
							.onSnapshot((snapshot) => {
								var sessions = [];
								snapshot.forEach((doc) => {
									sessions.push({ ...doc.data(), docId: doc.id });
								});
								sessions = _.orderBy(sessions, ['createdDate'], ['desc']);
								console.log(`Currently have ${sessions.length} active sessions`);
								appStore.activeSessions = sessions;
								appStore.sessionsLoaded = true;
							});
					} else {
						console.log('User Signing Out');
					}
				});

				//* SIGN IN
				firebase
					.auth()
					.signInAnonymously()
					.then(async () => {
						if (appStore.activeSessionId) {
							const openSession = await this.sessionInvite();
							if (openSession) {
								f7.views.main.router.navigate(`/session/${appStore.activeSessionId}`, {
									props: {
										parentSession: false,
										invited: true
									}
								});
							}
						}
					})
					.catch(function (error) {
						var errorCode = error.code;
						var errorMessage = error.message;
						console.error(`${errorCode}, ${errorMessage}`);
					});
			} catch (e) {
				console.error(e);
				console.log('Error loading the Firebase SDK, check the console.');
			}
		});
	}

	render() {
		return (
			<App params={this.state.f7params}>
				<View main functassName="safe-areas" url="/" />
			</App>
		);
	}
}
