import React, { Component } from 'react';

export default class Waves extends Component {
	render() {
		return (
			<div className="wave-ctn">
				<svg
					className="waves"
					xmlns="http://www.w3.org/2000/svg"
					preserveAspectRatio="none"
					shapeRendering="auto"
					viewBox="0 24 150 28"
				>
					<defs>
						<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
					</defs>
					<g className={this.props.staticAnimation ? '' : 'parallax'}>
						<use href="#gentle-wave" x="48" y="0" fill="rgba(0,133,255,1)" />
						<use href="#gentle-wave" x="48" y="3" fill="rgba(0,133,255,0.3)" />
						<use href="#gentle-wave" x="48" y="5" fill="rgba(0,133,255,0.5)" />
						<use href="#gentle-wave" x="48" y="7" fill="rgba(0,133,255,0.1)" />
					</g>
				</svg>
			</div>
		);
	}
}
