import { observer } from 'mobx-react';
import { observable } from 'mobx';

const autoBind = function (cmp, usingHOC, exclude) {
	const reactInternals = new Set([
		'constructor',
		'render',
		'componentWillMount',
		'componentDidMount',
		'componentWillReceiveProps',
		'shouldComponentUpdate',
		'componentWillUpdate',
		'componentDidUpdate',
		'componentWillUnmount'
	]);
	if (exclude && exclude.length > 0) {
		for (let i = 0; i < exclude.length; i++) {
			reactInternals.add(exclude[i]);
		}
	}
	let proto = cmp.__proto__;
	for (let key of Object.getOwnPropertyNames(proto)) {
		const { value } = Object.getOwnPropertyDescriptor(proto, key);
		// do not try to bind properties and getter/setters
		if (typeof value === 'function' && !reactInternals.has(key)) {
			cmp[key] = value.bind(cmp);
		}
	}
};
export { observable, observer, autoBind };
