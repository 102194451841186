import HomePage from '../pages/HomePage.js';
import NotFoundPage from '../pages/404.jsx';
import SessionPage from '../pages/SessionPage.js';

var routes = [
	{
		path: '/',
		component: HomePage
	},
	{
		path: '/session/:sessionId',
		component: SessionPage
	},
	{
		path: '(.*)',
		component: NotFoundPage
	}
];

export default routes;
