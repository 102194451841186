import { observable } from '../utils/State';
import _ from 'lodash';
const appStore = observable({
	activeSessionId: null,
	currentUserId: null,
	activeSessions: [],
	sessionsLoaded: false,
	currentLocation: {
		lat: null,
		lon: null,
		city: 'Unknown'
	}
});
window.appStore = appStore;
export default appStore;
