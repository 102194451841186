import Framework7 from 'framework7/framework7.esm.bundle.js';
import _ from 'lodash';
import LocationService from './LocationService';
const locationService = new LocationService();

class GeneralUtils {
	async nameCheck(f7) {
		let user = firebase.auth().currentUser;
		if (!user.displayName) {
			f7.dialog.close();
			return new Promise((resolve) => {
				const getNameDialog = (name) => {
					if (!_.isEmpty(name) && name.length <= 50) {
						user.updateProfile({ displayName: name }).then(() => {
							resolve();
						});
					} else {
						f7.dialog.close();
						f7.dialog.prompt(`Your friends will see this.`, `What's your name?`, getNameDialog, getNameDialog);
					}
				};
				f7.dialog.prompt(`Your friends will see this.`, `What's your name?`, getNameDialog, getNameDialog);
			});
		}
	}

	getLocation(f7) {
		return new Promise((resolve) => {
			const initManualEntry = () => {
				f7.dialog.prompt(
					'We were unable to obtain your location automatically, please enter a valid US postal code you would like to search from.',
					async (value) => {
						var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value.trim());
						if (!isValidZip) {
							f7.dialog.alert('Invalid Postal Code', () => {
								initManualEntry();
							});
						} else {
							f7.dialog.preloader();
							try {
								const locationSet = await locationService.getZipLocation(value.trim());
								if (locationSet) {
									f7.dialog.close();
									resolve(true);
								} else {
									f7.dialog.close();
									f7.dialog.alert('Invalid Postal Code', () => {
										initManualEntry();
									});
								}
							} catch (err) {
								f7.dialog.close();
								f7.dialog.alert('Invalid Postal Code', () => {
									initManualEntry();
								});
							}
						}
					},
					() => {
						initManualEntry();
					}
				);
			};
			if (navigator.geolocation) {
				f7.dialog.preloader('Getting Location...');
				navigator.geolocation.getCurrentPosition(
					async (position) => {
						const lat = _.get(position, 'coords.latitude');
						const lon = _.get(position, 'coords.longitude');
						await locationService.setPosition(lat, lon);
						f7.dialog.close();
						resolve(true);
					},
					(err) => {
						f7.dialog.close();
						initManualEntry();
						console.log('geo error here: ', err);
					},
					{ timeout: 5000 }
				);
			} else {
				initManualEntry();
			}
		});
	}
}
export default new GeneralUtils();
