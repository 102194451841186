import localforage from 'localforage';
import _ from 'lodash';
let instance;

export default class StorageService {
	constructor() {
		if (!instance) {
			instance = this;
			instance.storeMap = {};
			instance.subscriptionMap = {};
			instance.listening = false;
			instance._onStorageEventBinded = instance._onStorageEvent.bind(instance);
			instance._initStorage();
		}
		return instance;
	}

	_initStorage() {
		localforage.setDriver(localforage.LOCALSTORAGE);
		localforage
			.ready()
			.then(function () {
				if (!instance.listening) {
					window.addEventListener('storage', instance._onStorageEvent);
					instance.listening = true;
				}
			})
			.catch(function (e) {
				console.log(e);
			});
	}

	/**
	 *
	 * @param e
	 * @private
	 * fire subscription events if set for a key
	 */
	_onStorageEvent(e) {
		let events = instance.subscriptionMap[e.key];
		if (events && events.length > 0) {
			_.forEach(events, (toFire) => {
				toFire(e);
			});
		}
	}

	/**
	 *
	 * @param storeName
	 * @returns {localForage db Instance}
	 * @private
	 */
	_getStore(storeName) {
		return instance.storeMap[storeName];
	}

	/**
	 *
	 * @param storeName
	 * Create a new db in the storage service
	 */
	createStore(storeName) {
		instance.storeMap[storeName] = localforage.createInstance({
			name: storeName,
			driver: localforage.LOCALSTORAGE
		});
	}

	/**
	 *
	 * @param storeName
	 * @param key
	 * @returns {Promise that resolves to data or null}
	 */
	getValue(storeName, key) {
		let store = instance._getStore(storeName);
		if (store) {
			return store.getItem(key);
		}
		return Promise.resolve(null);
	}

	/**
	 *
	 * @param storeName
	 * @param key
	 * @param value
	 * @returns {Promise that resolves to data or null}
	 */
	setValue(storeName, key, value) {
		let store = instance._getStore(storeName);
		if (store) {
			return store.setItem(key, value);
		}
		return Promise.resolve(null);
	}

	/**
	 * @param storeName
	 */
	getStore(storeName) {
		let store = instance._getStore(storeName);
		if (store) {
			let storeArr = [];
			return store
				.iterate(function (value, key) {
					storeArr.push([key, value]);
				})
				.then(function () {
					return storeArr;
				})
				.catch(function (err) {
					throw err;
				});
		}
		return Promise.resolve(null);
	}

	/**
	 * @param storeName
	 */
	clearStore(storeName) {
		let store = instance._getStore(storeName);
		if (store) {
			return store
				.iterate(function (value, key) {
					store.removeItem(key);
				})
				.then(function () {
					// done iterating
					return true;
				})
				.catch(function (err) {
					throw err;
				});
		}
		return Promise.resolve(null);
	}

	/**
	 * @param storeName
	 */
	clearStoreKey(storeName, key) {
		let store = instance._getStore(storeName);
		if (store) {
			store.removeItem(key);
			return true;
		}
		return Promise.resolve(null);
	}

	/**
	 *
	 * @param storeName
	 * @param key
	 * @param method // method will be passed the storage event (has, new value, oldValue, etc)
	 * https://developer.mozilla.org/en-US/docs/Web/Events/storage
	 * Adds method to be fired on store/key change
	 */
	addSubscription(storeName, key, method) {
		let events = instance.subscriptionMap[`${storeName}/${key}`];
		if (!events) {
			events = [];
		}
		events.push(method);
		instance.subscriptionMap[`${storeName}/${key}`] = events;
	}

	/**
	 *
	 * @param storeName
	 * @param key
	 * Removes all subscriptions from a store/key
	 */
	removeSubscriptions(storeName, key) {
		if (instance.subscriptionMap[`${storeName}/${key}`]) {
			delete instance.subscriptionMap[`${storeName}/${key}`];
		}
	}

	/**
	 * @returns {string[store Names]}
	 */
	listStores() {
		return Object.keys(instance.storeMap);
	}
}
